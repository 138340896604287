<template>
  <v-app>
    <v-container>
      <v-row>
        <v-col>
          <v-app-bar color="#002643" dark prominent>
            <v-toolbar-title>
              <v-img src="./assets/logo.png" width="300"></v-img>
            </v-toolbar-title>
            <template #extension>
              <v-spacer></v-spacer>
              <v-btn
                :plain="!newReservation"
                :outlined="newReservation"
                @click="(newReservation = true), (step = 1)">
                <router-link tag="h4" :to="{ name: 'home' }">
                  <v-row>
                    <v-img src="./assets/add_box.png" width="20"></v-img>
                    <h4 style="margin-top:3px">حجز جديد</h4>
                  </v-row>
                </router-link
                ></v-btn>
              <v-btn
                :plain="newReservation"
                :outlined="!newReservation"
                @click="newReservation = false">
                <router-link tag="h4" :to="{ name: 'myReservations' }">
                  <v-row>
                    <v-img src="./assets/date.png" width="20"></v-img>
                    <h4 style="margin-top:3px">حجوزاتي</h4>
                  </v-row>  
                </router-link>
              </v-btn>
              <v-spacer></v-spacer>
            </template>
          </v-app-bar>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <router-view />
        </v-col>
      </v-row>
    </v-container>
    <loading-overlay v-if="showLoadingOverlay"></loading-overlay>
  </v-app>
</template>

<script>
//import axios from 'axios';

import LoadingOverlay from "./components/LoadingOverlay.vue";
import axios from "axios";

export default {
  components: {
    LoadingOverlay,
  },
  data() {
    return {
      showLoadingOverlay: false,
      newReservation: true,
    };
  },
  provide: {
    apiRoot: process.env.VUE_APP_API_ROOT,
  },
  created() {
    var component = this;
    //setting default call before any axios request
    //any default behavior before any api call should be added here
    axios.interceptors.request.use(
      (config) => {
        component.showLoadingOverlay = true; //show the loading screen before any api call
        return config;
      },
      function (error) {
        component.showLoadingOverlay = false;
        return Promise.reject(error);
      }
    );

    //setting default call after any axios request
    //any default behavir after any api call shoul be added here
    axios.interceptors.response.use(
      (response) => {
        component.showLoadingOverlay = false; //hiding the loading screen after any api call
        return response;
      },
      function (error) {
        component.showLoadingOverlay = false;
        return Promise.reject(error);
      }
    );
  },
  methods: {},
};
</script>
