import Vue from 'vue'
import Router from 'vue-router'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import SelectCalender from './components/SelectCalender'
import MyReservations from './components/MyReservations'
import OccurencesGrid from './components/OccurencesGrid'
import Occurence from './components/Occurence'
Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: SelectCalender,
    },
    {
      path: '/church/:id',
      name: 'church',
      component: SelectCalender,
      props: true,
    },
    {
      path: '/myreservations',
      name: 'myReservations',
      component: MyReservations
    },
    {
      path: '/calendar/:id',
      name: 'calendar',
      component: OccurencesGrid,
      props: true,
    },
    {
      path: '/occurence/:id',
      name: 'occurence',
      component: Occurence,
      props: true,
    }
  ],
  mode: 'history'
})

new Vue({
  vuetify,
  render: h => h(App),
  router
}).$mount('#app');