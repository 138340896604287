<template>
  <v-card>
    <v-toolbar dark color="#002643">
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-card-title>
          <h2>حجوزاتي</h2>
        </v-card-title>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container>
        <v-row v-if="reservations.length <= 0">
            <v-col>
            <v-card-text class="title" style="width:100%; margin:135px">ليس لديك حجوزات</v-card-text>
            </v-col>
        </v-row>
      <v-row v-for="reservation in reservations" :key="reservation.ID">
        <occurence-card
          :occurence="reservation"
          :vcard="true"
          :showDeleteBTN="true"
          :churchName="reservation.ChurchName"
          :calendarName="reservation.CalendarName"
          :showReservationType="true"
          @deleteReservation="deleteReservation(reservation)"
        ></occurence-card>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import axios from "axios";
import OccurenceCard from "./OccurenceCard.vue";

export default {
  components: { OccurenceCard },
  props: ["reservations", "NID"],
  inject: ['apiRoot'],
  methods: {
    close() {
      this.$emit("close");
    },
    deleteReservation(reservation) {
      if (confirm("هل انت متأكد من حذف هذا الحجز؟")) {
        axios
          .post(
            this.apiRoot + "reservations/" + reservation.ID,
            this.NID.toString()
          )
          .then((response) => {
            this.$emit('reservationDeleted', reservation);
          }
          , function (error) {
            alert(error);
          });

        return;
      }
    },
  },
};
</script>