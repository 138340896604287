<template>
  <div v-if="occurence != null">
    <occurence-card :occurence="occurence" :showAvailableSeats="true" :churchName="churchName" :calendarName="calendarName"></occurence-card>
    <v-divider></v-divider>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="title">الاسم</th>
            <th class="title">الرقم القومي</th>
            <th class="title">الموبايل</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="member in members"
            :key="member.NID"
            @click="setMember(Object.assign({}, member), true)"
          >
            <td class="subtitle-1">{{ member.Name }} {{member.IsDeacon ? ' - شماس' : ''}}</td>
            <td class="subtitle-1">{{ member.NID }}</td>
            <td class="subtitle-1">{{ member.Mobile }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-card-actions>
      <v-dialog
        v-model="showReservationForm"
        fullscreen
        transition="dialog-bottom-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            outlined
            color="blue"
            v-bind="attrs"
            v-on="on"
            :disabled="members.length >= getRemainingSeats()"
            @click="setMember(Object.assign({}, {IsDeacon: false} ), false)"
            >أضف أفراد</v-btn
          >
        </template>
        <new-reservation
          :OccurenceID="this.id"
          :member="emptyMember"
          :isEditMode="this.isEditMode"
          @close="showReservationForm = false"
          @save="addMemberToReservation"
          @deleteMember="deleteMember"
        ></new-reservation>
      </v-dialog>
      <v-spacer></v-spacer>
      <v-dialog
        v-model="showConfirmReservation"
        persistent
        fullscreen
        transition="dialog-bottom-transition"
      >
        <template v-slot:activator="{  }">
          <v-btn 
            outlined 
            color="blue"
            :disabled="members.length == 0"
            @click="confirmReservation"
            >تأكيد الحجز</v-btn
          >
        </template>
        <confirm-reservation
          :occurence="occurence"
          :churchName="churchName"
          :calendarName="calendarName"
          :members="members"
          @close="showConfirmReservation = false"
        ></confirm-reservation>
      </v-dialog>
    </v-card-actions>
  </div>
</template>

<script>
import axios from "axios";
import NewReservation from "./NewReservation.vue";
import ConfirmReservation from "./ConfirmReservation.vue";
import OccurenceCard from "./OccurenceCard.vue";

export default {
  components: {
    NewReservation,
    ConfirmReservation,
    OccurenceCard,
  },
  props: ['id'],
  data() {
    return {
      churchName: "",
      calendarName: "",
      occurence: null,
      showReservationForm: false,
      showConfirmReservation: false,
      isEditMode: false,
      members: [],
      currentMember: {},
      emptyMember: { IsDeacon: false },
      reservationConfirmation: {},
    };
  },
  inject: ['apiRoot'],
  watch: {
    '$route'() {
      this.getOccurence();
    }
  },
  created() {
    this.getOccurence();
  },
  methods: {
    getOccurence() {
      axios.get(this.apiRoot + "occurences/" + this.id)
        .then((response) => {
          this.occurence = response.data[0];
          this.calendarName = this.occurence.CalendarName;
          this.churchName = this.occurence.ChurchName;
        }, function (error) {
          alert(error);
        })
    },
    addMemberToReservation(newMember) {
      var isNewMember = this.members.filter((m) => m.NID == newMember.NID).length == 0;

      // If Editing an existing record
      if (this.isEditMode) {
        // If the NID Changed
        if (this.currentMember.NID != newMember.NID) {
          // If the new NID already exists in the list
          if (!isNewMember) {
            alert("تم إضافة هذا العضو من قبل");
            return;
          }
        }
		var existingMember = this.members.filter((m) => m.NID == this.currentMember.NID)[0];
        existingMember.Name = newMember.Name;
        existingMember.NID = newMember.NID;
        existingMember.Mobile = newMember.Mobile;
        existingMember.IsDeacon = newMember.IsDeacon;

      } else {
        // Adding new Member
		
		// If the new NID already exists in the list
        if (!isNewMember) {
          alert("تم إضافة هذا العضو من قبل");
          return;
        }
        this.members.push(newMember);
      }

      this.showReservationForm = false;
    },
    setMember(member, isEditMode) {
      this.emptyMember = Object.assign({}, member); // setting the member object that is sent to the reservation component
      this.currentMember = Object.assign({}, member);
      this.showReservationForm = true;
      this.isEditMode = isEditMode;
    },
    deleteMember(member) {
      this.members = this.members.filter((m) => m.NID != member.NID);
      this.showReservationForm = false;
    },
    confirmReservation() {
      axios
        .post(
          this.apiRoot +
            "occurences/" +
            this.id +
            "/reservations/confirm",
          this.members
        )
        .then((response) => {
          if (response.data.success)		 
            this.showConfirmReservation = true;
          else
            alert(response.data.message);
        }
        , function (error) {          
          alert(error);
        })
    },
    getRemainingSeats() {
      return this.occurence.RemainingSeats_Public
            + this.occurence.RemainingSeats_Deacons
            + this.occurence.RemainingSeats_Men
            + this.occurence.RemainingSeats_Women;
    }
  },
};
</script>