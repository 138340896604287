<template>
  <v-container>
    <v-row>
      <v-col>
        <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateFromFormatted"
            label="من"
            persistent-hint
            prepend-icon="mdi-calendar"
            v-bind="attrs"
            v-on="on"
            @blur="dateFrom = parseDate(dateFromFormatted)"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="dateFrom"
          @input="menu = false"
          @change="getOccurences"
        ></v-date-picker>
      </v-menu>
      <v-menu
        v-model="menu1"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateToFormatted"
            label="الى"
            persistent-hint
            prepend-icon="mdi-calendar"
            v-bind="attrs"
            v-on="on"
            @blur="dateTo = parseDate(dateToFormatted)"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="dateTo"
          @input="menu1 = false"
          @change="getOccurences"
        ></v-date-picker>
      </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>الاسم</th>
                <th>يوم</th>
                <th>المقاعد المتاحة</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="occurence in occurences" :key="occurence.ID" @click="setOccurence(occurence)">
                <td>{{ occurence.EventName }}</td>
                <td>{{ occurence.Date }}
					<span style="color: lightgrey;">{{ getTimeWithoutSeconds(occurence.StartTime) }} {{ getTimeWithoutSeconds(occurence.EndTime) }}</span>
				</td>
                <td>{{ occurence.RemainingSeats_Public + occurence.RemainingSeats_Deacons + occurence.RemainingSeats_Men + occurence.RemainingSeats_Women }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  props: ["id"],
  data: vm => ({

      dateFrom: new Date().toISOString().substr(0, 10),
      dateFromFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
      
      dateTo: new Date((new Date()).setMonth(new Date().getMonth() + 1)).toISOString().substr(0, 10),
      dateToFormatted: vm.formatDate(new Date((new Date()).setMonth(new Date().getMonth() + 1)).toISOString().substr(0, 10)),
      
      menu: false,
      menu1: false,
      occurences: [],
      currentOccurence: {},
    })
  ,
  inject: ['apiRoot'],
  watch: {
    dateFrom (val) {
      this.dateFromFormatted = this.formatDate(this.dateFrom)
    },
    dateTo (val) {
      this.dateToFormatted = this.formatDate(this.dateTo)
    },
  },
  created() {
    this.getOccurences();
  },
  methods: {
    getOccurences() {
      axios
        .post(this.apiRoot + "calendars/" + this.id + "/occurences", {
          StartDate: this.dateFrom,
          EndDate: this.dateTo,
        })
        .then((response) => {
          this.occurences = response.data;
        }
        , function (error) {
          alert(error);
        });
      return;
    },
    setOccurence(occurence) {
      this.$router.push({name: 'occurence', params: {id: occurence.ID}});
    },
    formatDate (date) {
		if (!date) return null

		const [year, month, day] = date.split('-')
		return `${day}/${month}/${year}`
    },
    parseDate (date) {
        if (!date) return null

        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
	getTimeWithoutSeconds(time) {
        var timeParts = time.split(':');
        return timeParts[0] + ':' + timeParts[1];
    },
  },
};
</script>