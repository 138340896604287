<template>
  <div>
    <v-text-field v-show="false" v-model="member.Name" label="ألاسم" outlined></v-text-field>
    <v-text-field
      v-model="member.NID"
      label="الرقم القومي"
      outlined
    ></v-text-field>
    <v-dialog
      v-model="showMyReservations"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{}">
        <v-btn outlined color="blue" @click="getMemberReservations">تحقق</v-btn>
      </template>
      <reservations-grid
        @close="showMyReservations = false"
        :reservations="memberReservations"
        :NID="member.NID"
        @reservationDeleted="reservationDeleted"
      ></reservations-grid>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import ReservationsGrid from "./ReservationsGrid.vue";

export default {
  components: {
    ReservationsGrid,
  },
  data() {
    return {
      showMyReservations: false,
      member: {
        Name: "",
        NID: "",
      },
      memberReservations: [],
    };
  },
  inject: ['apiRoot'],
  methods: {
    getMemberReservations() {
      if (this.member.NID == "") {
        alert("يرجو إدخال الرقم القومي");
        return;
      }
      axios
        .post(this.apiRoot + "reservations/member", this.member.NID.toString())
        .then((response) => {
          this.memberReservations = response.data;
          this.showMyReservations = true;
          
        }
        , function (error) {
          alert(error);
        });
      return;
    },
    reservationDeleted(reservation) {
      this.memberReservations = this.memberReservations.filter(
        (r) => r.ID != reservation.ID
      );
    },
  },
};
</script>