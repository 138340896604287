<template>
  <v-container>
    <v-row>
      <v-col>
        <v-autocomplete
            v-model="currentChurch"
            :items="churches"
            :filter="filterChurch"
            background-color="#dfeffc"
            item-text="Name"
            label="الكنيسة"
            return-object            
            outlined
            @change="getCalendars"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
            v-model="currentCalendar"
            :items="calendars"
            item-text="Name"
            item-value="ID"
            background-color="#dfeffc"
            label="التقويم"
            persistent-hint
            return-object
            outlined            
            @change="setCalendar"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
      <v-btn v-if="currentCalendar.Name != null" @click="setCalendar">بحث</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      churches: [],
      currentChurch: {},
      calendars: [],
      currentCalendar: {},
    }
  },
  inject: ['apiRoot'],
  props:['id'],
  created() {
    const churchParam = this.$route.query.church;
    if(churchParam) {
      this.$router.push({name: 'church', params: {id: churchParam}});
      return;
    }
    this.getChurches();
  },
  watch: {
    '$route'() {
      this.getChurches();
    }
  },
  methods: {
    getParameterByName (name, url = window.location.href) {
      name = name.replace(/[[\]]/g, '\\$&')
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url)
      if (!results) return null
      if (!results[2]) return ''
      return decodeURIComponent(results[2].replace(/\+/g, ' '))
    },

    async getChurches () {
      axios
          .get(this.apiRoot + 'churches')
          .then( (response) => {
            this.churches = response.data;
            const churchParam = this.id;
            if(!churchParam) return;
            const church = this.churches.find(e => e.ID == churchParam);

            if(!church) return;

            this.currentChurch = church;
            this.getCalendars();
          }
          , function (error) {
            alert(error)
          })
      return
    },
    getCalendars () {
      axios
          .get(this.apiRoot + 'churches/' + this.currentChurch.ID + '/calendars')
          .then((response) => {
            this.calendars = response.data
            if(this.calendars.length == 1) {
              this.currentCalendar = this.calendars[0];
              this.setCalendar();
            }
          }
          , function (error) {
            alert(error)
          })
      return
    },
    filterChurch (item, queryText) {
      const textOne = item.Name.toLowerCase()
      const searchText = queryText.toLowerCase()

      return textOne.indexOf(searchText) > -1
    },
    setCalendar () {
      this.$router.push({name: 'calendar', params: {id: this.currentCalendar.ID}});
    },
  },
}
</script>
