<template>
  <div :class="[vcard ? 'v-card v-sheet theme--light' : '']" Style="width:100%;">
    <v-card-title style="padding-top:0;!important">
      <h3 class="title" Style="Color:#002643;"><strong> {{ occurence.EventName }}</strong></h3>
      <h3 class="body-1 ms-2">({{ calendarName }})</h3>
      <v-spacer></v-spacer>      
      <v-btn v-if="showDeleteBTN" icon color="red" @click="deleteReservation">
        <v-icon>mdi-delete-outline</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="body-1">
      <v-row >
           {{ churchName }}
      </v-row>
      <v-row>
        <v-col>
          <v-row>
            <h3 v-if="showReservationType" class="body-1">نوع الحجز: {{ occurence.ReservationType }}</h3>
          </v-row>
          <v-row class="body-1">
            <v-col style="padding: 12px 0px;">
              <strong>يوم: </strong>{{ occurence.Date }}
            </v-col>
            <v-col>
              <strong>من </strong>{{ getTimeWithoutSeconds(occurence.StartTime) }}<strong> إلى </strong>{{ getTimeWithoutSeconds(occurence.EndTime) }}            
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="occurence.Description != null && occurence.Description != ''">
        <h3 class="body-1">{{ occurence.Description }}</h3>
      </v-row>
      <v-row v-if="showAvailableSeats">
        <strong>المقاعد المتاحة</strong>
      </v-row>
      <v-row v-if="showAvailableSeats" dense>
        <v-col>
        عام : {{ occurence.RemainingSeats_Public }}
        </v-col>
        <v-col>
        للشمامسة : {{ occurence.RemainingSeats_Deacons }}
        </v-col>
      </v-row>
      <v-row v-if="showAvailableSeats" dense>
        <v-col>
        للرجال : {{ occurence.RemainingSeats_Men }}
        </v-col>
        <v-col>
        للسيدات : {{ occurence.RemainingSeats_Women }}
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
export default {
  props: ["occurence", "churchName", "calendarName", "showAvailableSeats", "showReservationType", "vcard", "showDeleteBTN"],
  data() {
    return {};
  },
  methods: {
      getTimeWithoutSeconds(time) {
        var timeParts = time.split(':');
        return timeParts[0] + ':' + timeParts[1];
    },
    deleteReservation(){
      this.$emit('deleteReservation');
    }
  }
};
</script>