<template>
    <v-card>
        <v-toolbar dark color="#002643">
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-card-title>
                    <h2 Style="Color:lightgreen;">تم تأكيد الحجز</h2>
                </v-card-title>
            </v-toolbar-items>
            <v-spacer></v-spacer>            
        </v-toolbar>
        <v-container>
        <v-row>
            <occurence-card :occurence="occurence" :churchName="churchName" :calendarName="calendarName"></occurence-card>
        </v-row>
        <v-row>
        <v-divider></v-divider>
        </v-row>
        <v-row>
            <v-card-subtitle>
                <h1 class="dsiplay-4">قائمة الأفراد</h1>
            </v-card-subtitle>
        </v-row>
        <v-row>
            <v-simple-table Style="width:800px;">
                <template v-slot:default>
                <thead>
                    <tr>
                    <th class="title">الاسم</th>
                    <th class="title">الرقم القومي</th>
                    <th class="title">الموبايل</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="member in members"
                    :key="member.NID"
                    @click="setMember(Object.assign({}, member), true)"
                    >
                    <td class="subtitle-1">{{ member.Name }}</td>
                    <td class="subtitle-1">{{ member.NID }}</td>
                    <td class="subtitle-1">{{ member.Mobile }}</td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
        </v-row>
    </v-container>
    </v-card>
</template>

<script>
import OccurenceCard from "./OccurenceCard.vue";
export default {
    components: {
        OccurenceCard
    },
    props:['occurence', 'churchName', 'calendarName','members'],
    data() {
        return {

        };
    },
    methods: {
        close() {
            this.$emit("close", false);
        },
    }
}
</script>