<template>
  <v-card color="#dfeffc">
    <v-toolbar dark color="#002643">
      <v-toolbar-items>
        <v-card-title>
          {{ isEditMode ? "بيانات الفرد" : "أضف لقائمة الأفراد" }}
        </v-card-title>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container style="padding: 50px">
      <v-row>
        <v-text-field
          name="Reservation_Name"
          v-model="member.Name"
          label="الاسم"
          filled
          background-color="#dfeffc"
        ></v-text-field>
      </v-row>
      <v-row>
        <v-text-field
          name="Reservation_NID"
          v-model="member.NID"
          label="الرقم القومي"
          filled
          background-color="#dfeffc"
        ></v-text-field>
      </v-row>
      <v-row>
        <v-text-field
          name="Reservation_Name"
          v-model="member.Mobile"
          label="رقم الموبايل"
          filled
          background-color="#dfeffc"
        ></v-text-field>
      </v-row>
      <v-row>
        <v-checkbox
          v-model="member.IsDeacon"
          label="حجز شماس"
        ></v-checkbox>
      </v-row>
      <v-row>
        <v-card-subtitle
          v-html="
            memberValidity != null && memberValidity[0] != null
              ? memberValidity[0].message
              : ''
          "
          Style="Color:red;"
          class="title"
        ></v-card-subtitle>
        <v-spacer></v-spacer>
      </v-row>
    </v-container>
    <v-card-actions style="padding: 50px">
      <v-btn outlined color="blue" @click="getMemberValidity">تحقق</v-btn>
      <v-spacer></v-spacer>
      <v-btn v-if="isEditMode" outlined color="red" @click="deleteMember"
        >حذف</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  props: ["OccurenceID", "member", "isEditMode"],
  data() {
    return {
      memberValidity: null,
    };
  },
  watch: {
    member: function (value) {
      this.memberValidity = null;
    },
  },
  inject: ['apiRoot'],
  methods: {
    close() {
      this.$emit("close", false);
    },
    getMemberValidity() {
      axios
        .post(
          this.apiRoot +
            "occurences/" +
            this.OccurenceID +
            "/reservations/check",
          [
            {
              Name: this.member.Name,
              NID: this.member.NID,
              Mobile: this.member.Mobile,
              IsDeacon: this.member.IsDeacon,
            },
          ]
        )
        .then((response) => {
          this.memberValidity = response.data;
          this.checkMemberValidity();
        }
        , function (error) {
          alert(error);
        });
    },
    checkMemberValidity() {
      if (!this.memberValidity[0].success) return;
      this.$emit("save", this.member);
    },
    deleteMember() {
      this.$emit("deleteMember", this.member);
    },
  },
};
</script>